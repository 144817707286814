import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slider from '@mui/material/Slider';
import React, { useCallback, useEffect, useState } from 'react';
import { ImageGallery } from './ImageGallery';

const QuickGridDialog = React.memo(
  ({ open, handleClose, images, onImageSelect }) => {
    const [localImages, setLocalImages] = useState(images);
    const [imageHeight, setImageHeight] = useState(274);

    useEffect(() => {
      setLocalImages(images);
    }, [images]);

    const handleDialogClose = useCallback(() => {
      handleClose();
      if (onImageSelect) onImageSelect(localImages);
    }, [handleClose, onImageSelect, localImages]);

    const handleSliderChange = (event, newValue) => {
      setImageHeight(newValue);
    };

    return (
      <Dialog
        fullWidth
        maxWidth='lg'
        onClose={handleDialogClose}
        aria-labelledby='dialog-title'
        open={open}
        PaperProps={{ style: { maxHeight: '80vh' } }} // Ensure dialog doesn't exceed view height
      >
        <DialogTitle
          id='dialog-title'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {'Quickview Receipt Gallery'}
          <Slider
            value={imageHeight}
            onChange={handleSliderChange}
            aria-labelledby='image-height-slider'
            min={208}
            max={495}
            style={{ width: '300px', marginLeft: '20px' }}
          />
        </DialogTitle>
        <DialogContent
          dividers
          style={{
            overflowY: 'auto',
            touchAction: 'auto',
          }}
        >
          <ImageGallery
            images={localImages}
            onImageSelect={setLocalImages}
            imageHeight={imageHeight}
          />
        </DialogContent>
      </Dialog>
    );
  }
);

export default QuickGridDialog;
