import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import ReceiptDialog from "./ReceiptDialog";

const useStyles = makeStyles({
    root: {
        maxWidth: 400,
        textAlign: '-webkit-center',
        margin: '16px 0'
    },
    media: {
        width: 368,
        minHeight: 600,
        margin: '16px 0'
    },
});

const ImageUrlPropTypes = {
    imgId: PropTypes.string.isRequired,
};

export default function ReceiptCard(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    
    return (
        <div>
            <Card className={classes.root}>
                <CardActionArea onClick={handleClickOpen}>
                    <CardMedia
                        className={classes.media}
                        image={props.imgId}
                        title="Receipt"
                    >
                    </CardMedia>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {props.date}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            <ReceiptDialog title="Receipt Image" image={props.imgId} open={open} onClose={handleClose} />
        </div>
    );
}
ReceiptCard.propTypes = ImageUrlPropTypes;
