import { Navigate } from 'react-router-dom';
import { useAuthState } from './firebase';

export const ProtectedRoute = ({ redirectPath = '/', children }) => {
  const { isAuthenticated } = useAuthState();
  if (!isAuthenticated) return <Navigate to={redirectPath} replace />;
  return children;
};

export const OpenRoute = ({ redirectPath = 'admin/redeems', children }) => {
  const { isAuthenticated } = useAuthState();
  if (isAuthenticated) return <Navigate to={redirectPath} replace />;
  return children;
};
