import { ChevronLeft, MeetingRoom } from '@mui/icons-material';
import {
  Avatar,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { getAuth, signOut } from 'firebase/auth';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useAuthState } from '../firebase';
import { menuList, styles } from './NavigatorStyles';

function Navigator(props) {
  const { classes, index, open, sx, toggle } = props;
  const [menu, setMenu] = React.useState(menuList);
  const { user } = useAuthState();
  const handleDrawer = () => toggle();
  const handleIndex = n => {
    index(n);
    toggle();
  };
  return (
    <Drawer
      variant='persistent'
      open={open}
      PaperProps={{ sx: { backgroundColor: '#f7f8f9' } }}
    >
      <List disablePadding>
        <ListItem
          className={clsx(
            classes.spaceship,
            classes.item,
            classes.itemCategory
          )}
        >
          <Grid container style={{ justifyContent: 'space-between' }}>
            <Typography variant='h6'>Maholla 🛰{'\n'}</Typography>
            <IconButton onClick={handleDrawer}>
              <ChevronLeft />
            </IconButton>
          </Grid>
          <Typography>Spaceship</Typography>
        </ListItem>
        <ListItem className={clsx(classes.item, classes.itemCategory)}>
          <ListItemIcon className={classes.itemIcon}>
            <Avatar alt='Avatar' src={user?.photoURL} variant='circular' />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary
            }}
          >
            {user?.displayName}
          </ListItemText>
          <IconButton
            color='inherit'
            onClick={() => signOut(getAuth())}
            size='large'
          >
            <MeetingRoom />
          </IconButton>
        </ListItem>
        {menu.map(({ id: childId, icon, path, active }, index) => (
          <ListItem
            key={childId}
            component={Link}
            to={`${path}`}
            underline='hover'
            button
            onClick={() => {
              setMenu(
                menu.map(function (x) {
                  x.active = false;
                  return x;
                })
              );
              const newArr = [...menu];
              Object.assign(newArr[index], { active: true });
              setMenu(newArr);
              handleIndex(index);
            }}
            className={clsx(classes.item, active && classes.itemActiveItem)}
          >
            <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary
              }}
            >
              {childId}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
  index: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  sx: PropTypes.string,
  toggle: PropTypes.func.isRequired
};

export default withStyles(styles)(Navigator);
