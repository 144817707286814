import { SupportAgent } from '@mui/icons-material';
import { Avatar, Badge, Container, IconButton } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import * as React from 'react';

const BASE_URL = process.env.REACT_APP_API;

export default function AgentPopover() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [agents, setAgents] = React.useState([]);
  React.useEffect(() => {
    async function fetchData() {
      const res = await axios.get(`${BASE_URL}/admin/v2/getActiveAgents`, {
        active: false
      });
      setAgents(res.data.DATA);
    }
    fetchData();
  }, []);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        aria-label={'supportAgent'}
        sx={{
          width: 56,
          height: 56
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
      >
        <Badge variant='dot' color='approve'>
          <SupportAgent color='dark' />
        </Badge>
      </IconButton>
      <Popover
        id='mouse-over-popover'
        sx={{
          pointerEvents: 'none'
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0px 8px !important'
          }}
        >
          {agents && agents.length > 0 ? (
            agents.map((el, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '4px 4px',
                  alignItems: 'center'
                }}
              >
                <Avatar alt={el.fullName} src={el.avatar} />
                <Typography sx={{ paddingLeft: '8px' }}>
                  {el.fullName}
                </Typography>
              </div>
            ))
          ) : (
            <Typography sx={{ padding: '8px' }}>No active agents.</Typography>
          )}
        </Container>
      </Popover>
    </div>
  );
}
