import React from 'react';
import { TablePagination, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

const ImagePagination = ({ classes, images, page, handlePage, handleBad }) => (
  <table style={{ width: '100%' }}>
    <thead>
      <tr className={classes.receiptHeader}>
        <TablePagination
          count={images.length}
          page={page}
          rowsPerPage={1}
          onPageChange={handlePage}
          color='secondary'
          rowsPerPageOptions={[]}
          sx={{ borderBottom: 0 }}
        />
        <td className={classes.checkbox}>
          <Checkbox
            className={images[page]?.isSelected ? classes.checked : ''}
            label='bad receipt'
            checked={images[page]?.isSelected || false}
            onChange={handleBad}
            inputProps={{ 'aria-label': 'controlled' }}
            icon={<CircleOutlinedIcon />}
            checkedIcon={<HighlightOffOutlinedIcon />}
          />
          Bad
        </td>
      </tr>
    </thead>
  </table>
);

ImagePagination.propTypes = {
  classes: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  handlePage: PropTypes.func.isRequired,
  handleBad: PropTypes.func.isRequired,
};

export default ImagePagination;
