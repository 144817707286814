import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { blue } from '@mui/material/colors';
 
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  media: {
    height: 'auto',
    minWidth: '300px',
    maxWidth: '500px'
  }
});

export default function ReceiptDialog(props) {
  const classes = useStyles();
  const { onClose, image, open, title } = props;
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="dialog-title" open={open}>
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
      <img className={classes.media} src={image} alt="Receipt" title="Receipt" />
      </DialogContent>
    </Dialog>
  );
}

ReceiptDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  image: PropTypes.string.isRequired,
};
