export const styles = theme => ({

  padding: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(5),
  },
  loginWrapper: {
    display: 'flex',
    width: '100vw',
    justifyContent: 'center',
    height: '100vh',
    alignItems: 'center'
  },
  media: {
    paddingLeft: '12px !important',
    width: 175,
    height: 'auto'
  },
  textLogo: {
    alignSelf: 'center',
    maxWidth: '150px !important'
  },
  gridLogo: {
    width: 'auto',
    marginRight: '36px',
    paddingBottom: '36px'
  }
});
