import { getAuth, onAuthStateChanged } from '@firebase/auth';
import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { axiosInstance } from './axios';
import { createContext, useContext, useEffect, useState } from 'react';

export const firebaseApp = initializeApp({
  apiKey: 'AIzaSyDU3FYEsoMGrVv544B-KCAXEKIA98Gb-YQ',
  authDomain: 'com-maholla-app.firebaseapp.com',
  projectId: 'com-maholla-app',
  storageBucket: 'com-maholla-app.appspot.com',
  messagingSenderId: '259151057145',
  appId: '1:259151057145:web:d89620b9c61840f6d35228',
  measurementId: 'G-7K503P7EFB'
});

export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
  const [user, setUser] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      getAuth(),
      async (auth) => {
        if (auth) {
          const claims = (await auth.getIdTokenResult()).claims;
          const token = await auth.getIdToken(true);
          if (!claims.role) {
            await axiosInstance.post('/admin/v2/signin', {
              avatar: auth.photoURL,
            });
            const roleToken = await auth.getIdToken(true);
            axios.defaults.headers['Authorization'] = `Bearer ${roleToken}`;
          } else axios.defaults.headers['Authorization'] = `Bearer ${token}`;
          setUser(auth);
        } else setUser(null);
      },
      setError
    );
    return () => unsubscribe();
  }, []);
  return <AuthContext.Provider value={{ user, error }} {...props} />;
};

export const useAuthState = () => {
  const auth = useContext(AuthContext);
  return { ...auth, isAuthenticated: auth && auth.user != null };
};
