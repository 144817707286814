import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Slide from '@mui/material/Slide';
import * as React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    duplicates: false,
    fake: false,
    modification: false,
    ownership: false,
  });

  const handleClickOpen = () => {
    if (!props.data) alert(props.alertMessage);
    else setOpen(true);
  };

  const handleYes = () => {
    setOpen(false);
    props.handleFraud(duplicates, fake, modification, ownership);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { duplicates, fake, modification, ownership } = state;
  const fraudLength = [duplicates, fake, modification, ownership].filter(
    (v) => v
  ).length;
  const error = fraudLength < 1;

  return (
    <div>
      <Button
        className={props.styles}
        onClick={handleClickOpen}
        disabled={props.disabled}
      >
        <span role='img' aria-label='investigator'>
          {props.buttonEmoji}
        </span>
        {`${props.buttonTitle}`}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            {props.description}
          </DialogContentText>
        </DialogContent>
        <Box sx={{ display: 'flex' }}>
          <FormControl
            required
            error={error}
            component='fieldset'
            sx={{ m: 3 }}
            variant='standard'
          >
            <FormLabel component='legend'>Pick at least one</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={duplicates || false}
                    onChange={handleChange}
                    name='duplicates'
                  />
                }
                label='Duplicate'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={fake || false}
                    onChange={handleChange}
                    name='fake'
                  />
                }
                label='Fake'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={modification || false}
                    onChange={handleChange}
                    name='modification'
                  />
                }
                label='Modification'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ownership || false}
                    onChange={handleChange}
                    name='ownership'
                  />
                }
                label='Ownership Descrepancy'
              />
            </FormGroup>
            {error && <FormHelperText>You have selected {fraudLength} reason.</FormHelperText>}
          </FormControl>
        </Box>
        <DialogActions>
          <Button disabled={error} onClick={handleYes}>
            Yes
          </Button>
          <Button onClick={handleClose}>No</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
