import * as React from 'react';
import { withStyles } from '@mui/styles';
import { Badge, Button, Popover } from '@mui/material';

const styles = ({
    actionButton: {
        color: '#fff !important',
        fontWeight: '600 !important',
        fontSize: 'smaller',
        width: '110px',
        '& span': {
            paddingRight: '8px'
        }
    },
    process: {
        backgroundColor: '#8ab9f4 !important'
    },
    popoverProcess: {
        margin: 16,
        display: 'flex',
        flexDirection: 'column',
        '& Button': {
            color: '#fc3501 !important',
            padding: 8,
            width: 215
        },
        '& .MuiBadge-badge': {
            backgroundColor: '#fc3501 !important'
        },
        '& .MuiBadge-root': {
            margin: 8
        }
    }
});

function PopoverProcess(props) {
    const { classes } = props;
    const [anchor, setAnchor] = React.useState(null);
    const open = Boolean(anchor);
    const id = open ? 'simple-popover' : undefined;
    const handlePopover = (event) => {
        setAnchor(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchor(null);
    };
    return (
        <div>
            <Button className={[classes.actionButton, classes.process].join(' ')}
                onClick={handlePopover}>
                <span role="img" aria-label="green-tick">♻️</span>
                PROCESS
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchor}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <div className={classes.popoverProcess}>
                    <Badge badgeContent={props.balance.length} color="secondary">
                        <Button onClick={_ => { props.process('INSUFFICIENT_BALANCE'); handlePopoverClose(); }}
                            disabled={!props.balance.length}
                            variant="outlined">Insuficient Balance</Button>
                    </Badge>
                    <Badge badgeContent={props.maintenance.length} color="secondary">
                        <Button onClick={_ => { props.process('AIRTIME_SERVICE_IN_MAINTENANCE'); handlePopoverClose(); }}
                            disabled={!props.maintenance.length}
                            variant="outlined">Reloadly Maintenance</Button>
                    </Badge>
                    <Badge badgeContent={props.other.length} color="secondary">
                        <Button onClick={_ => { props.process('REDEEM_NOT_POSSIBLE'); handlePopoverClose(); }}
                            disabled={true}
                            variant="outlined"><div>Redeem Not Possible</div></Button>
                    </Badge>
                </div>
            </Popover>
        </div>
    );
}

export default withStyles(styles)(PopoverProcess);