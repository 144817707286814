import {
  MeetingRoom,
  Menu,
  PersonSearchRounded,
  RuleRounded,
  SupportAgent,
} from '@mui/icons-material';
import {
  Avatar,
  Badge,
  CssBaseline,
  Fab,
  IconButton,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import axios from 'axios';
import { getAuth, signOut } from 'firebase/auth';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import AgentPopover from '../../components/AgentPopover';
import Navigator from '../../components/Navigator';
import { useAuthState } from '../../firebase';
import Function from '../../functions/Function';
import Redeems from '../../functions/redeems/Redeems';
import { styles } from './BaseStyles';
import pj from '../../../package.json';

function Copyright() {
  return (
    <Typography variant='body2' color='text.secondary' align='center'>
      {'Copyright © '}
      <Link style={{ color: '#fc3500' }} to='https://maholla.com/'>
        Maholla B.V.
      </Link>
      {` ${new Date().getFullYear()} ${pj.version}`}
    </Typography>
  );
}

function Base(props) {
  const { classes } = props;
  const [open, setOpen] = React.useState(false);
  const [admin, setAdmin] = React.useState(false);
  const [index, setIndex] = React.useState(1);
  const { user } = useAuthState();

  React.useEffect(() => {
    async function fetchData() {
      const { role } = await (
        await getAuth().currentUser.getIdTokenResult()
      ).claims;
      if (role === 'admin') setAdmin(true);
    }
    fetchData();
  }, []);

  const handleSignOut = async () => {
    signOut(getAuth());
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={open ? classes.drawer : classes.closed}>
        <Fab
          aria-label='menu'
          onClick={handleDrawerToggle}
          className={classes.fab}
        >
          <Menu />
        </Fab>
        <Fab aria-label='avatar' className={classes.fab}>
          <Avatar alt='Avatar' src={user?.photoURL} variant='circular' />
        </Fab>
        <Fab
          aria-label='avatar'
          className={classes.fab}
          onClick={() => handleSignOut()}
        >
          <MeetingRoom color='dark' />
        </Fab>
        {/* <Link to='search' onClick={() => setIndex(0)}>
          <Fab aria-label='search' className={classes.fab}>
            <PersonSearchRounded
              color={index != null && index === 0 ? 'selected' : 'dark'}
            />
          </Fab>
        </Link> */}
        <Link to='redeems' onClick={() => setIndex(1)}>
          <Fab aria-label='redeems' className={classes.fab}>
            <RuleRounded color={index && index === 1 ? 'selected' : 'dark'} />
          </Fab>
        </Link>
        {admin && <AgentPopover />}
        <Navigator
          index={(n) => setIndex(n)}
          open={open}
          toggle={() => handleDrawerToggle()}
        />
      </nav>
      <div className={classes.app}>
        <main className={open ? classes.openMain : classes.closedMain}>
          <Routes>
            <Route path={'redeems'} element={<Redeems />} />
            <Route path={':functionId'} element={<Function />} />
          </Routes>
        </main>
        <footer className={classes.footer}>
          <Copyright />
        </footer>
      </div>
    </div>
  );
}

Base.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Base);
