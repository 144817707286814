import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
  Divider,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PropTypes from 'prop-types';
import { BadgeContainer, BadgeWrapper } from './StyledComponents';

const UserInfoCard = ({ classes, user, uid, completedProfile }) => (
  <Card>
    <CardHeader
      title={`${user.firstName || 'User'} ${user.lastName || 'Details'}`}
      titleTypographyProps={{ variant: 'h6' }}
      sx={{ '.MuiCardHeader-title': { fontWeight: 500 } }}
      style={{ paddingBottom: '0px' }}
    />
    <CardContent className={classes.userInfo}>
      <BadgeContainer>
        <BadgeWrapper checked={completedProfile()}>📝</BadgeWrapper>
        <BadgeWrapper checked={user.arcadeUser || false}>🕹️</BadgeWrapper>
      </BadgeContainer>

      <Typography
        sx={{ fontSize: 14, display: 'flex', alignItems: 'center' }}
        color='text.secondary'
        gutterBottom
      >
        {uid && (
          <>
            User ID: {uid}
            <IconButton
              onClick={() => navigator.clipboard.writeText(uid)}
              sx={{ marginLeft: 1 }}
              size='small'
            >
              <ContentCopyIcon fontSize='small' />
            </IconButton>
            <br />
          </>
        )}
      </Typography>
      <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
        Phone number: {user.phoneNumber}
        <br />
        Network provider: {user.provider?.title || ''}
      </Typography>
      <Divider />
      <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
        Current balance: {user.totalNumberOfPoints} pts
        <br />
        Lifetime points: {user.lifetimePoints} pts
      </Typography>
      <Divider />
      <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
        Successful referrals: {user.referrals?.length || 0}
        <br />
        Referral points earned: {user.referrals?.length || 0} pts
      </Typography>
      <Divider />
      <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
        Successful redeems: {user.totalSuccessfulRedeems || 0}
        <br />
        Total amount redeemed: R {user.totalPointsRedeemed / 100 || 0}
      </Typography>
    </CardContent>
  </Card>
);

UserInfoCard.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  uid: PropTypes.string,
  completedProfile: PropTypes.func.isRequired,
};

export default UserInfoCard;
