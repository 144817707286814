export const styles = (theme) => ({
  approve: {
    backgroundColor: '#2dce89 !important',
  },
  reject: {
    backgroundColor: '#f75851 !important',
  },
  warn: {
    backgroundColor: '#f7ad51 !important',
  },
  disabled: {
    backgroundColor: 'grey !important',
  },
  actionButton: {
    color: '#fff !important',
    fontWeight: '600 !important',
    fontSize: 'smaller',
    marginRight: '12px !important',
    width: '110px',
    '& span': {
      paddingRight: '8px',
    },
  },
  quickviewButton: {
    backgroundColor: '#51a7f9 !important',
    color: '#fff !important',
    fontWeight: '600 !important',
    fontSize: 'smaller',
    marginRight: '12px !important',
    width: '110px',
    '& span': {
      paddingRight: '8px',
    },
  },
  receiptImage: {
    objectFit: 'cover',
    maxHeight: '73vh',
  },
  receiptHeader: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  checked: {
    color: '#f75851 !important',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
  },
  userInfo: {
    paddingTop: '4px !important',
    '& hr': {
      margin: '12px 0',
    },
  },
});
