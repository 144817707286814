import '@fontsource/pacifico'
import '@fontsource/roboto'
import '@fontsource/roboto/500.css'
import React from 'react'
import {
  BrowserRouter as Router, Route,
  Routes
} from 'react-router-dom'
import './App.css'
import { AuthContextProvider } from './firebase'
import Redeems from './functions/redeems/Redeems'
// import Search from './functions/search/Search'
import Base from './pages/base/Base'
import Signin from './pages/signin/Signin'
import { OpenRoute, ProtectedRoute } from './routeTypes'

class App extends React.Component {
  render() {
    return (
      <AuthContextProvider>
        <Router>
          <Routes>
            <Route
              index
              element={
                <OpenRoute>
                  <Signin />
                </OpenRoute>
              }
            />
            <Route
              path='admin/*'
              element={
                <ProtectedRoute>
                  <Base />
                </ProtectedRoute>
              }
            >
              {/* <Route path='search' element={<Search />} /> */}
              <Route path='redeems' element={<Redeems />} />
            </Route>
          </Routes>
        </Router>
      </AuthContextProvider>
    );
  }
}

export default App;
