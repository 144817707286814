import * as React from 'react';
import MuiBackdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Typography } from '@mui/material';

export default function Backdrop(props) {
  return (
    <div>
      <MuiBackdrop
        sx={{ color: '#fff', zIndex: 10002 }}
        open={props.loading}
      >
        <Grid sx={{ textAlign: 'center' }}>
          <CircularProgress sx={{ margin: '1vh' }} color="inherit" />
          <Typography>{props.text||'Processing...'}</Typography>
        </Grid>
      </MuiBackdrop>
    </div>
  );
}