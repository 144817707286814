import { PersonSearchRounded, RuleRounded } from '@mui/icons-material';

export const menuList = [
  {
    id: 'Users',
    icon: <PersonSearchRounded />,
    path: 'search',
    active: false
  },
  {
    id: 'Redeem Monitoring',
    icon: <RuleRounded />,
    path: 'redeems',
    active: false
  }
];

export const styles = theme => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.black
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: '#000',
    '&:hover, &:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.98)'
    }
  },
  itemCategory: {
    backgroundColor: '#fff ',
    boxShadow: '0 -1px 0 #c1c2c5 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  spaceship: {
    flexDirection: 'column',
    alignItems: 'flex-start !important',
    fontSize: 24,
    color: theme.palette.common.black
  },
  itemActiveItem: {
    color: '#1eb1f3 !important',
    backgroundColor: 'aliceblue !important'
  },
  itemPrimary: {
    fontSize: 'inherit'
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(2)
  }
});
