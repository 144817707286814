import { createTheme } from '@mui/material/styles';

export let theme = createTheme({
  palette: {
    primary: {
      light: '#ffffff',
      main: '#fc3500',
      dark: '#222222'
    },
    secondary: {
      main: '#ff9133'
    },
    selected: {
      main: '#1eb1f3'
    },
    frozen: {
      main: '#66ffff'
    },
    approve: {
      main: '#2dce89'
    },
    reject: {
      main: '#f75851'
    },
    dark: {
      main: '#222222'
    },
    subtle: {
      main: '#f7f8f9'
    }
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5
    }
  },
  shape: {
    borderRadius: 8
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true
      }
    }
  },
  mixins: {
    toolbar: {
      minHeight: 48
    }
  }
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#fff'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        label: {
          textTransform: 'none'
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none'
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1)
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1)
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#d5d5d5'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: theme.typography.fontWeightMedium
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          marginRight: 0,
          '& svg': {
            fontSize: 20
          }
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32
        }
      }
    }
  }
};

export const drawerWidth = 'auto';

export const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    },
    display: 'flex',
    flexDirection: 'column'
  },
  closed: {
    [theme.breakpoints.up('sm')]: {
      width: 0,
      flexShrink: 0
    }
  },
  fab: {
    backgroundColor: '#00000000 !important',
    boxShadow: 'none !important'
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  closedMain: {
    flex: 1,
    paddingVertical: 6,
    paddingLeft: 36,
    paddingRight: 16,
    background: '#fff',
    marginLeft: 24
  },
  openMain: {
    flex: 1,
    paddingVertical: 6,
    paddingRight: 16,
    paddingLeft: 0,
    background: '#fff',
    margin: 0
  },
  footer: {
    marginTop: theme.spacing(2),
    background: '#f7f8f9',
    padding: theme.spacing(2),
    zIndex: 1300
  }
};
