import { useCallback, useEffect, useState } from 'react';

export const ImageGallery = ({ images, onImageSelect, imageHeight }) => {
  const [localImages, setLocalImages] = useState(images);

  useEffect(() => {
    setLocalImages(images);
  }, [images]);

  const handleSelect = useCallback(
    (index) => {
      const updatedImages = localImages.map((img, i) =>
        i === index ? { ...img, isSelected: !img.isSelected } : img
      );
      setLocalImages(updatedImages);
    },
    [localImages]
  );

  useEffect(() => {
    if (onImageSelect) onImageSelect(localImages);
  }, [localImages, onImageSelect]);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
      {localImages.map((image, index) => (
        <div
          key={index}
          style={{ position: 'relative', display: 'inline-block' }}
        >
          <img
            src={image.src}
            alt={image.alt}
            style={{
              height: `${imageHeight}px`, // Use slider value for height
              objectFit: 'cover',
              border: image.isSelected
                ? '2px solid #f75851'
                : '2px solid transparent',
              cursor: 'pointer',
            }}
            onClick={() => handleSelect(index)}
          />
          {image.isSelected && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                backgroundColor: '#f75851',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontSize: '16px',
                pointerEvents: 'none',
              }}
            >
              ✓
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
