import { GridOverlay } from '@mui/x-data-grid';
import { LinearProgress } from '@mui/material';

const TableLoader = () => {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress color="secondary" />
            </div>
        </GridOverlay>
    );
}

export default TableLoader;