export const initState = () => ({
  agentId: '',
  alert: {},
  approved: true,
  backdrop: false,
  checked: false,
  dialogImg: {},
  disabled: true,
  images: [],
  imageCount: -1,
  loading: false,
  open: false,
  page: 0,
  pending: { loading: false },
  phoneNumber: '',
  processBalance: {},
  processMaintenance: {},
  processNotPossible: {},
  reps: [],
  rows: [],
  successfulRedeems: 0,
  user: {},
  uid: '',
});

export const resetState = () => ({
  approved: true,
  disabled: true,
  images: [],
  imageCount: -1,
  loading: false,
  open: false,
  page: 0,
  phoneNumber: '',
  successfulRedeems: 0,
  reps: [],
  rows: [],
  user: {},
  uid: '',
});
