export const initDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('MahollaRedeemsImageDB', 1);

    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve(request.result);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains('images')) {
        db.createObjectStore('images', { keyPath: 'docId' });
      }
    };
  });
};

export const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      // console.log('Blob converted to base64');
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      console.error('Error in blobToBase64:', error);
      reject(error);
    };
    reader.readAsDataURL(blob);
  });
};
