import React from 'react';
import { Grid, Button } from '@mui/material';
import PropTypes from 'prop-types';
import QuickGridDialog from '../../components/QuickGridDialog';
import AlertDialog from '../../components/AlertDialog';

const OutcomeButtons = ({
  classes,
  disabled,
  approved,
  handleRedeem,
  handleDialogOpen,
  handleDialogClose,
  dialogOpen,
  images,
  loading,
  handleImageSelect,
  redeem,
  handleFraud,
}) => (
  <Grid container sx={{ minWidth: '360px' }}>
    <Grid item xs={3}>
      <Button
        className={[
          classes.actionButton,
          disabled ? classes.disabled : classes.quickviewButton,
        ].join(' ')}
        onClick={handleDialogOpen}
        disabled={disabled}
      >
        <span role='img' aria-label='red-alarm'>
          🖼️
        </span>
        GRID
      </Button>
      <QuickGridDialog
        open={dialogOpen}
        handleClose={handleDialogClose}
        images={images}
        onImageSelect={handleImageSelect}
      />
    </Grid>
    <Grid item xs={3}>
      <AlertDialog
        alertMessage={'Please select a redeem!'}
        buttonEmoji={'🕵️'}
        buttonTitle={'FRAUD'}
        data={redeem}
        disabled={loading || disabled}
        description={
          'This action will set this user to frozen and delete their redeem. Are you certain this is what you want to do?'
        }
        handleFraud={handleFraud}
        styles={[
          classes.actionButton,
          disabled ? classes.disabled : classes.warn,
        ].join(' ')}
        title={'Are you sure?'}
      />
    </Grid>
    <Grid item xs={3}>
      <Button
        className={[
          classes.actionButton,
          loading || !approved || disabled ? classes.disabled : classes.approve,
        ].join(' ')}
        disabled={loading || !approved || disabled}
        onClick={() => handleRedeem('APPROVED')}
      >
        <span role='img' aria-label='green-tick'>
          ✅
        </span>
        APPROVE
      </Button>
    </Grid>
    <Grid item xs={3}>
      <Button
        className={[
          classes.actionButton,
          loading || approved || disabled ? classes.disabled : classes.reject,
        ].join(' ')}
        disabled={loading || approved || disabled}
        onClick={() => handleRedeem('REJECTED')}
      >
        <span role='img' aria-label='red-alarm'>
          🚨
        </span>
        REJECT
      </Button>
    </Grid>
  </Grid>
);

OutcomeButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  approved: PropTypes.bool.isRequired,
  handleRedeem: PropTypes.func.isRequired,
  handleDialogOpen: PropTypes.func.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  images: PropTypes.array.isRequired,
  handleImageSelect: PropTypes.func.isRequired,
  redeem: PropTypes.object,
  handleFraud: PropTypes.func.isRequired,
};

export default OutcomeButtons;
