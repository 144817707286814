import axios from 'axios';
import { getAuth } from '@firebase/auth';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Token caching
let cachedToken = null;
let tokenExpiry = null;

// Helper function to check if the token is expired
const isTokenExpired = () => {
  if (!tokenExpiry) return true;
  // Check if the current time is past the token's expiry time
  return Date.now() >= tokenExpiry;
};

// Request interceptor to add the auth token to every request
axiosInstance.interceptors.request.use(
  async (config) => {
    // Fetch a new token only if necessary
    if (isTokenExpired()) {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        const token = await currentUser.getIdToken(true); // Force refresh
        const decodedToken = await currentUser.getIdTokenResult();
        cachedToken = token;
        // Set the token expiry time (subtract 5 minutes to account for any discrepancies)
        tokenExpiry = new Date(decodedToken.expirationTime).getTime() - 5 * 60 * 1000;
      }
    }
    if (cachedToken) {
      config.headers['Authorization'] = `Bearer ${cachedToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
