import React from "react";
import { useParams } from "react-router-dom";
// import Search from './search/Search';
import Redeems from "./redeems/Redeems";

export default function Function() {
    let { functionId } = useParams();
    return (
        <div>
            {/* {functionId === 'search' && <Search />} */}
            {functionId === 'redeems' && <Redeems />}
        </div>
    );
}
