import { getAuth } from '@firebase/auth';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { axiosInstance } from '../../axios';
import {
  Alerts,
  Backdrop,
  NoRowsOverlay,
  RefreshButton,
  TableLoader,
} from '../../components';
import { blobToBase64, initDB } from '../../utils';
import ImagePagination from './ImagePagination';
import OutcomeButtons from './OutcomeButtons';
import { initState, resetState } from './RedeemsState';
import { styles } from './RedeemsStyles';
import { ActiveSwitch, BadgeContainer, BadgeWrapper } from './StyledComponents';
import UserInfoCard from './UserInfoCard';

class Redeems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initState(),
      dialogOpen: false,
      backdropText: null,
    };
    this.progressRef = createRef(0);
    this.worker = null;
  }

  componentDidMount() {
    this.getActive();
    document.addEventListener('keydown', this._handleKeyDown);
  }

  componentWillUnmount() {
    this.cancelImageLoading();
    document.removeEventListener('keydown', this._handleKeyDown);
  }

  reset = () => {
    const img = document.getElementById('receiptImg');
    if (img) img.style.display = 'block';
    this.setState(resetState());
  };

  _handleKeyDown = (event) => {
    const { approved, disabled, images, page } = this.state;
    switch (event.key) {
      case 'Enter':
        !disabled &&
          this.handleRedeem(approved === true ? 'APPROVED' : 'REJECTED');
        break;
      case 'ArrowLeft':
        page > 0 &&
          this.setState((prevState) => ({ page: prevState.page - 1 }));
        break;
      case 'ArrowRight':
        page < images.length - 1 &&
          this.setState((prevState) => ({ page: prevState.page + 1 }));
        break;
      case 'ArrowDown':
        this.handleBad({
          target: { checked: !images[page].isSelected },
        });
        break;
      default:
        break;
    }
  };

  getActive = () => {
    fetch('/admin/v2/getActiveAgent');
    const fetchActiveAgent = async () => {
      try {
        const res = await axiosInstance.get('/admin/v2/getActiveAgent');
        if (res?.data?.DATA) {
          this.setState({
            checked: res.data.DATA.active,
            agentId: res.data.DATA._id,
          });
        }
      } catch (err) {
        this.setState({ backdrop: false });
        this.handleAlert(
          'error',
          'Error occurred. Check console for detail.',
          2000
        );
        console.error(err);
      }
    };
    fetchActiveAgent();
  };

  activateAgent = (active) => {
    this.setState({ checked: active });
    axiosInstance
      .post(`/admin/v2/activateAgent`, { active })
      .then((res) => {
        this.handleAlert(
          'info',
          active ? 'Set to active.' : 'Set to inactive.',
          1500
        );
      })
      .catch((err) => {
        this.setState({ backdrop: false });
        this.handleAlert(
          'error',
          'Error occurred. Check console for detail.',
          2000
        );
        console.error(err);
      });
  };

  pendingRedeems = async (isSync) => {
    this.reset();
    // Initially set backdrop to true to indicate loading
    this.setState({ backdrop: true });
    try {
      const res = await axiosInstance.get(
        `/redeem/v2/agentRedeems?status=PENDING`
      );

      res.data.DATA &&
        res.data.DATA.sort(
          (a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)
        );
      // Prepare the rows data outside of the setState call
      const rows = res.data.DATA
        ? res.data.DATA.map((row, i) => {
            return {
              id: i + 1,
              userID: row.userId,
              date: row.dateCreated,
              amount: `R ${row.points / 100}`,
              receipts: row.receipts.sort(
                (a, b) => new Date(b.docId) - new Date(a.docId)
              ),
            };
          })
        : [];
      // Consolidate setState calls into one, updating all necessary state variables at once
      this.setState({
        backdrop: false,
        loading: false,
        pending: res.data && (res.data.DATA || true),
        rows: rows,
      });
      // Handle the alert outside of the setState to keep it side-effect free
      if (isSync) this.handleAlert('info', 'Syncing pending redeems', 1500);
    } catch (err) {
      // Handle error by setting backdrop to false and showing an alert
      this.setState({ backdrop: false });
      this.handleAlert(
        'error',
        'Error occurred. Check console for detail.',
        2000
      );
      console.error(err);
    }
  };

  handleRedeem = async (status) => {
    try {
      this.setState({ backdrop: true });
      const { _id, receipts, userId } = this.state.redeem;
      if (this.state.agentId === '')
        this.setState(async (prevState) => ({
          agentId:
            (await getAuth().currentUser.getIdTokenResult()).claims.aid ||
            prevState.agentId,
        }));
      const redeem = {
        _id,
        agentId: this.state.agentId,
        receipts: receipts ? [...receipts] : [],
        status,
        userId,
      };

      // Set the status for redeem
      redeem.status = status;
      if (
        redeem.receipts &&
        redeem.receipts.length > 0 &&
        this.state.images.length > 0
      ) {
        // Extract docIds from the sorted images array
        const imageDocIds = this.state.images.map((image) => image.docId);

        // Sort the receipts array based on the order of docIds in imageDocIds
        redeem.receipts.sort(
          (a, b) => imageDocIds.indexOf(a.docId) - imageDocIds.indexOf(b.docId)
        );

        // Update the status of each receipt based on the bad state
        for (let i = 0; i < redeem.receipts.length; i++) {
          const e = this.state.images[i].isSelected;
          redeem.receipts[i].status =
            e != null ? (e ? 'REJECTED' : 'APPROVED') : 'APPROVED';
        }
      }

      // Make an API call to submit the redeem
      await axiosInstance.put(`/redeem/v2/submitRedeem`, {
        _id: this.state.uid,
        status,
        redeem,
      });

      // Refresh the pending redeems list and update the state
      await this.pendingRedeems();
      this.setState({ backdrop: false });
      this.handleAlert('success', `Redeem sent with status: ${status}`, 2500);
    } catch (err) {
      // Handle errors and update the state
      this.setState({ backdrop: false });
      console.error(err);
      this.handleAlert(
        'error',
        'Error occurred. Check console for details.',
        3000
      );
      await this.pendingRedeems();
    }
  };

  handleFraud = async (duplicates, fake, modification, ownership) => {
    this.setState({ backdrop: true });
    axiosInstance
      .post(`/admin/v2/fraud`, {
        decision: { duplicates, fake, modification, ownership },
        redeemId: this.state.redeem._id,
        userId: this.state.redeem.userId,
      })
      .then((_) => {
        this.pendingRedeems()
          .then((_) => this.setState({ backdrop: false }))
          .catch((err) => {
            this.setState({ backdrop: false });
            console.error(err);
          });
      })
      .catch((err) => {
        this.setState({ backdrop: false });
        console.error(err);
      });
  };

  fetchImages = async (docIds) => {
    this.setState({ backdrop: true, backdropText: 'Fetching image URLs...' });

    try {
      const response = await axiosInstance.post(`/admin/v2/imageUrls`, {
        docIds,
      });
      const imageUrls = response.data.DATA;
      this.setState({ imageCount: imageUrls.length });

      // console.log('Fetched image URLs:', imageUrls);

      const initialBatchSize = 10;
      this.setState({ backdropText: 'Loading initial images...' });

      const initialImages = [];
      for (let i = 0; i < Math.min(initialBatchSize, imageUrls.length); i++) {
        try {
          const image = await this.loadSingleImage(imageUrls[i]);
          // console.log(`Loaded initial image ${i + 1}:`, {
          //   docId: image.docId,
          //   srcLength: image.src ? image.src.length : 0,
          //   isPlaceholder: image.src === '/images/no-image.png',
          // });
          initialImages.push(image);

          this.setState((prevState) => ({
            images: [...prevState.images, image],
          }));
        } catch (error) {
          console.error(`Error loading initial image ${i + 1}:`, error);
          initialImages.push({
            docId: imageUrls[i].docId,
            src: '/images/no-image.png',
            isSelected: false,
            width: 320,
          });
        }
      }

      // console.log(
      //   'All initial images loaded:',
      //   initialImages.map((img) => ({
      //     docId: img.docId,
      //     srcLength: img.src ? img.src.length : 0,
      //     isPlaceholder: img.src === '/images/no-image.png',
      //   }))
      // );

      this.setState({
        backdrop: false,
        backdropText: null,
        loading: imageUrls.length > initialBatchSize,
      });

      if (imageUrls.length > initialBatchSize) {
        this.loadRemainingImages(imageUrls.slice(initialBatchSize));
      }
    } catch (error) {
      console.error('Error fetching image URLs:', error);
      this.setState({
        backdrop: false,
        backdropText: null,
        loading: false,
      });
    }
  };

  loadSingleImage = async (imageData) => {
    const { docId, url } = imageData;
    // console.log(`Attempting to load image: ${docId}, URL: ${url}`);
    const db = await initDB();
    const tx = db.transaction('images', 'readwrite');
    const store = tx.objectStore('images');
    return new Promise(async (resolve, reject) => {
      try {
        let image = await store.get(docId);
        // console.log(`Result of IndexedDB get for ${docId}:`, image);

        if (!image || !image.src) {
          // console.log(
          //   `Image ${docId} not found in IndexedDB or src is missing, fetching from URL`
          // );
          const imgResponse = await fetch(url);
          if (!imgResponse.ok) {
            throw new Error(`HTTP error! status: ${imgResponse.status}`);
          }
          const blob = await imgResponse.blob();
          const base64data = await blobToBase64(blob);

          image = {
            docId,
            src: base64data,
            isSelected: false,
            width: 320,
          };
          const tx = db.transaction('images', 'readwrite');
          const store = tx.objectStore('images');
          await store.put(image);
          await tx.done;
          // console.log(`Image ${docId} stored in IndexedDB`);
        } else {
          // console.log(`Image ${docId} found in IndexedDB`);
        }

        await tx.done;
        // console.log(`Transaction completed for ${docId}`);
        resolve(image);
      } catch (error) {
        // console.error(`Error in loadSingleImage for ${docId}:`, error);
        resolve({
          docId,
          src: '/images/no-image.png',
          isSelected: false,
          width: 320,
        });
      }
    });
  };

  cancelImageLoading = () => {
    if (this.worker) {
      this.worker.postMessage({ type: 'cancel' });
      this.worker.terminate();
      this.worker = null;
    }
  };

  loadRemainingImages = (imageUrls) => {
    this.cancelImageLoading(); // Cancel any ongoing loading

    this.worker = new Worker('/imageLoader.worker.js');

    this.worker.onmessage = async (event) => {
      if (event.data.type === 'done') {
        this.setState({ loading: false });
        this.worker.terminate();
        this.worker = null;
      } else if (event.data.type === 'batch') {
        const db = await initDB();
        const tx = db.transaction('images', 'readwrite');
        const store = tx.objectStore('images');

        try {
          for (const { docId, base64data } of event.data.data) {
            const image = {
              docId,
              src: base64data,
              isSelected: false,
              width: 320,
            };
            await store.put(image);
          }

          await tx.done;

          this.setState((prevState) => ({
            images: [
              ...prevState.images,
              ...event.data.data.map(({ docId, base64data }) => ({
                docId,
                src: base64data,
                isSelected: false,
                width: 320,
              })),
            ],
          }));
        } catch (error) {
          console.error('Error storing batch in IndexedDB:', error);
        }
      } else if (event.data.type === 'error') {
        console.error(
          `Error loading image ${event.data.docId}:`,
          event.data.error
        );
      }
    };

    this.worker.onerror = (error) => {
      console.error('Worker error:', error);
      this.setState({ loading: false });
    };

    this.worker.postMessage({ imageUrls });
  };

  fetchUser = async (uid) => {
    try {
      const res = await axiosInstance.post(`/admin/v2/uid`, { userId: uid });
      // this.setState({ backdrop: false });
      const userData = res.data.DATA;
      if (userData.details) {
        userData.firstName = userData.details.firstName;
        userData.lastName = userData.details.lastName;
      }
      this.setState({ user: userData });
    } catch (err) {
      // this.setState({ backdrop: false });
      console.error(err);
    }
  };

  handleAlert = (type, message, time) => {
    const alert = { open: true, type, message, time };
    this.setState({ alert });
    setTimeout(() => {
      this.setState({ alert: {} });
    }, time);
  };

  clearImagesDB = async () => {
    const db = await initDB();
    const tx = db.transaction('images', 'readwrite');
    const store = tx.objectStore('images');
    await store.clear();
    await tx.complete;
  };

  handleRowClick = async (data) => {
    this.cancelImageLoading(); // Cancel any ongoing loading

    const index = data.row.id - 1;
    const pendingItem = this.state.pending[index];

    await this.clearImagesDB();

    this.setState({
      backdrop: true,
      disabled: false,
      images: [],
      loading: true,
      page: 0,
      redeem: pendingItem,
      uid: data.row.userID,
    });

    this.fetchUser(data.row.userID);
    const img = document.getElementById('receiptImg');
    if (img) img.style.display = 'none';
    const docIds = data.row.receipts.map((receipt) => receipt.docId);
    this.fetchImages(docIds);
  };

  handleExampleClick = (e, data) => {
    e.stopPropagation();
    this.setState({
      dialogImg: { url: `/images/${data.key}.png`, title: data.label },
      open: true,
    });
  };

  handleBad = (event) => {
    this.setState((currentState) => {
      // Clone the images array from the current state
      const newImages = currentState.images.map((image, index) => {
        // Update the isSelected field for the image at the current page
        if (index === currentState.page) {
          return { ...image, isSelected: event.target.checked };
        }
        return image;
      });

      // Determine if any image is selected
      const isAnyImageSelected = newImages.some((image) => image.isSelected);

      // Return the updated state
      return {
        images: newImages,
        approved: !isAnyImageSelected,
      };
    });
  };

  handleDialogOpen = () => {
    this.setState({ dialogOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  };

  handleImageSelect = (updatedImages) => {
    const isAnyImageSelected = updatedImages.some((image) => image.isSelected);
    this.setState({ images: updatedImages, approved: !isAnyImageSelected });
  };

  render() {
    const { classes } = this.props;
    const {
      alert,
      approved,
      backdrop,
      backdropText,
      checked,
      dialogOpen,
      disabled,
      loading,
      images,
      imageCount,
      page,
      pending,
      redeem,
      rows,
      uid,
      user,
    } = this.state;
    const columns = [
      { field: 'date', headerName: 'Date', minWidth: 215, flex: 1 },
      { field: 'amount', headerName: 'Amount', minWidth: 75, flex: 0.42 },
    ];
    const handlePage = (event, value) => {
      this.setState({ page: value });
      const img = document.getElementById('receiptImg');
      if (img) img.style.display = 'none';
      this.forceUpdate();
    };

    const completedProfile = () =>
      user?.details?.firstName &&
      user?.details?.lastName &&
      user?.details?.email &&
      user?.details?.birthday &&
      user?.details?.gender;

    return (
      <Grid container spacing={2}>
        <Backdrop loading={backdrop} text={backdropText} />
        {alert.open && (
          <Alerts type={alert.type} message={alert.message} time={alert.time} />
        )}
        <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              marginTop: '16px',
              maxHeight: '95vh',
            }}
          >
            <CardHeader
              action={
                <>
                  <RefreshButton
                    func={() => {
                      this.pendingRedeems(true);
                    }}
                  />
                  <ActiveSwitch
                    sx={{ m: 1 }}
                    checked={checked}
                    onChange={(e, checked) => this.activateAgent(checked)}
                  />
                </>
              }
              title='⏳ Pending redeems'
              titleTypographyProps={{ variant: 'h6' }}
              sx={{ '.MuiCardHeader-title': { fontWeight: 500 } }}
            />
            <CardContent sx={{ height: '100%' }}>
              <div style={{ height: '100%', width: '100%' }}>
                <DataGrid
                  disableColumnMenu
                  disableExtendRowFullWidth
                  disableSelectionOnClick
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NoRowsOverlay,
                  }}
                  loading={pending.loading}
                  onRowClick={(e) => {
                    this.handleRowClick(e);
                  }}
                  rowsPerPage={10}
                  rowsPerPageOptions={[10, 25, 50, 75, 100]}
                  {...{ rows, columns, maxColumns: 2 }}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={8} sx={{ marginTop: '16px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title='⏰ Outcome'
                  titleTypographyProps={{ variant: 'h6' }}
                  action={
                    <OutcomeButtons
                      classes={classes}
                      disabled={disabled}
                      approved={approved}
                      handleRedeem={this.handleRedeem}
                      handleDialogOpen={this.handleDialogOpen}
                      handleDialogClose={this.handleDialogClose}
                      dialogOpen={dialogOpen}
                      images={images}
                      loading={loading}
                      handleImageSelect={this.handleImageSelect}
                      redeem={redeem}
                      handleFraud={this.handleFraud}
                    />
                  }
                  sx={{
                    '.MuiCardHeader-action': { alignSelf: 'center' },
                    '.MuiCardHeader-title': { fontWeight: 500 },
                  }}
                />
              </Card>
            </Grid>
            <Grid item xs={7}>
              <Card sx={{ maxWidth: '66.666667vw', position: 'relative' }}>
                {
                  <ImagePagination
                    classes={classes}
                    images={images}
                    page={page}
                    handleBad={this.handleBad}
                    handlePage={handlePage}
                  />
                }
                <CardContent
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'stretch',
                    overflow: 'auto',
                    padding: '0',
                    maxHeight: '75vh',
                    minHeight: '75vh',
                  }}
                >
                  {images.length > 0 ? (
                    <img
                      className={classes.receiptImage}
                      id='receiptImg'
                      src={
                        (images[page] && images[page].src) ||
                        '/images/no-image.png'
                      }
                      onLoad={(e) => {
                        // console.log(
                        //   `Image loaded: ${e.target.src.slice(0, 50)}`
                        // );
                        e.target.style.display = '';
                      }}
                      alt='receipt'
                    />
                  ) : imageCount !== -1 ? (
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <BadgeContainer>
                        <BadgeWrapper
                          checked
                          style={{
                            fontSize: '42px',
                            width: '90px',
                            height: '90px',
                            backgroundColor: '#51a7f960',
                            marginRight: 0,
                          }}
                        >
                          🙅
                        </BadgeWrapper>
                      </BadgeContainer>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color='text.secondary'
                        fontWeight={500}
                        gutterBottom
                      >
                        No receipts to display.
                      </Typography>
                    </Box>
                  ) : null}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={5}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <UserInfoCard
                    classes={classes}
                    user={user}
                    uid={uid}
                    completedProfile={completedProfile}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

Redeems.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Redeems);
