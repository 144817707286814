import { IconButton } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';

const RefreshButton = (props) => {
    return (
        <IconButton onClick={() => { props.func(true) }} aria-label="sync" size="large">
            <SyncIcon fontSize="inherit" />
        </IconButton>
    )
}

export default RefreshButton;